import { colorMap } from '@lib/colors'
import {
  BaseCartLineEdge,
  CurrencyCode,
  ImageConnection,
  MoneyV2,
  ProductOption,
  ProductVariantConnection,
  SelectedOption,
  Cart as ShopifyCart,
  Product as ShopifyProduct,
} from '../schema'
import type { Cart, LineItem } from '../types/cart'
import type {
  ContextualizeProduct,
  ContextualizeProductVariantConnection,
  ContextualizeShopifyProduct,
  Hit,
  Product,
  SiblingProduct,
} from '../types/product'

type Field = {
  key: string
  value: string
}
export const COLOUR_CODE_TAG_PREFIX = 'colour-code:'
export const POPULAR_TAG = 'popular'

const money = ({ amount, currencyCode }: MoneyV2) => {
  return {
    value: +amount,
    currencyCode,
  }
}

const normalizeProductOption = ({
  id,
  name: displayName,
  values,
}: ProductOption) => {
  return {
    __typename: 'MultipleChoiceOption',
    id,
    displayName: displayName.toLowerCase(),
    values: values.map((value) => {
      let output: any = {
        label: value,
      }
      if (displayName.match(/colou?r/gi)) {
        const mapedColor = colorMap[value.toLowerCase().replace(/ /g, '')]
        if (mapedColor) {
          output = {
            ...output,
            hexColors: [mapedColor],
          }
        }
      }
      return output
    }),
  }
}

const normalizeProductImages = ({ edges }: ImageConnection) =>
  edges?.map(({ node: { url, ...rest } }) => ({
    url,
    ...rest,
  }))

const normalizeProductVariants = ({ edges }: ProductVariantConnection) => {
  return edges?.map(
    ({
      node: {
        id,
        selectedOptions,
        sku,
        title,
        price,
        compareAtPrice,
        requiresShipping,
        availableForSale,
        image,
        weight,
        weightUnit,
        metafields,
      },
    }) => {
      return {
        id,
        name: title,
        sku: sku ?? id,
        price: +price.amount,
        listPrice: +compareAtPrice?.amount,
        requiresShipping,
        availableForSale,
        weight: weight,
        weightUnit: weightUnit,
        image: image
          ? {
            altText: image.altText,
            src: image.src,
          }
          : null,
        options: selectedOptions.map(({ name, value }: SelectedOption) => {
          const options = normalizeProductOption({
            id,
            name,
            values: [value],
          })

          return options
        }),
        metafields: metafields ? normalizeMetafields(metafields) : [],
      }
    }
  )
}

export function normalizeMetafields(metafields: any) {
  if (!metafields) return []
  const fields: Field[] = []
  metafields
    ?.filter((m: any) => m)
    .forEach((m: any) => {
      fields.push({
        key: m.key,
        value: m.value,
      })
    })
  return fields
}

export function normalizeProduct({
  id,
  title: name,
  vendor,
  images,
  variants,
  description,
  descriptionHtml,
  handle,
  priceRange,
  compareAtPriceRange,
  options,
  metafields,
  tags,
  ...rest
}: ShopifyProduct): Product {
  return {
    id,
    name,
    vendor,
    path: `/product/${handle}`,
    handle,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    price: money(priceRange?.minVariantPrice),
    listPrice: money(compareAtPriceRange?.minVariantPrice),
    images: normalizeProductImages(images),
    tags,
    colourCode:
      tags
        ?.filter((item) => item.startsWith(COLOUR_CODE_TAG_PREFIX))?.[0]
        ?.replace(COLOUR_CODE_TAG_PREFIX, '') || null,
    variants: variants ? normalizeProductVariants(variants) : [],
    metafields: metafields ? normalizeMetafields(metafields) : [],
    options: options
      ? options
        .filter((o) => o.name !== 'Title' && o.name !== 'Título')
        // By default Shopify adds a 'Title' name when there's only one option. We don't need it. https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
        // 'Título' is 'Title' in spanish, need to add more if needed.
        .map((o) => normalizeProductOption(o))
      : [],
    popular: tags?.includes(POPULAR_TAG) || false,
    ...(description && { description }),
    ...(descriptionHtml && { descriptionHtml }),
    ...rest,
  }
}

export function normalizeColourSiblingProduct({
  handle,
  tags,
  availableForSale,
  ...rest
}: ShopifyProduct): SiblingProduct {
  return {
    path: `/product/${handle}`,
    handle,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    availableForSale,
    ...rest,
  }
}

export function normalizeCart(cart: ShopifyCart): Cart {
  return {
    id: cart.id,
    url: cart.checkoutUrl,
    customerId: cart.buyerIdentity.customer?.id,
    email: cart.buyerIdentity.customer?.email || '',
    createdAt: cart.createdAt,
    currency: {
      code: cart.cost.totalAmount?.currencyCode,
    },
    taxesIncluded: false,
    lineItems: cart.lines?.edges.map(normalizeLineItem),
    lineItemsSubtotalPrice: +cart.cost.subtotalAmount?.amount,
    subtotalPrice: +cart.cost.subtotalAmount?.amount,
    totalPrice: cart.cost.totalAmount?.amount,
    discounts: cart.discountAllocations,
  }
}

function normalizeLineItem({
  node: { id, merchandise, cost, quantity, attributes, discountAllocations },
  node,
}: BaseCartLineEdge): LineItem {
  const preOrder = merchandise?.product.tags.find((tag) =>
    tag.includes(`${merchandise.sku} SHIPS`)
  )
  return {
    id,
    variantId: String(merchandise?.id),
    productId: String(merchandise?.product.id),
    tags: merchandise?.product?.tags ? merchandise.product.tags : [],
    customAttributes: attributes ? attributes : [],
    preorder: preOrder ? preOrder : '',
    name: `${merchandise.product.title}`,
    type: merchandise?.product.productType || '',
    quantity,
    subtotalAmount: Number(cost?.subtotalAmount?.amount),
    totalAmount: Number(cost?.totalAmount?.amount),
    variant: {
      id: String(merchandise?.id),
      sku: merchandise?.sku ?? '',
      name: merchandise?.title!,
      image: {
        url: merchandise?.image?.url || '/product-img-placeholder.svg',
      },
      requiresShipping: merchandise?.requiresShipping ?? false,
      price: merchandise?.price?.amount,
      listPrice: merchandise?.compareAtPrice?.amount,
    },
    path: String(merchandise?.product?.handle),
    discounts: discountAllocations,
    options:
      merchandise?.title == 'Default Title' ? [] : merchandise?.selectedOptions,
  }
}

export const normalizeAlgoliaHit = (
  {
    objectID,
    title,
    vendor,
    handle,
    body_html_safe,
    price,
    compare_at_price,
    tags,
    image,
    named_tags,
    product_type,
    meta,
    ...rest
  }: Hit,
  currencyCode: CurrencyCode
): Product => {
  const fields: any[] = []
  return {
    name: title,
    vendor,
    handle,
    productType: product_type,
    description: body_html_safe,
    path: `/product/${handle}`,
    variants: [],
    images: image
      ? [
        {
          url: image,
        },
      ]
      : [],
    price: money({
      amount: price,
      currencyCode,
    }),
    listPrice: money({
      amount: compare_at_price,
      currencyCode,
    }),
    tags,
    colourCode:
      named_tags?.[COLOUR_CODE_TAG_PREFIX.replace(':', '')] || '#ffffff',
    popular: tags?.includes(POPULAR_TAG) || false,
    metafields: undefined,
    media: undefined,
    colourCardHandle: meta?.my_fields?.colour_card,
    ...rest,
    id: objectID,
  }
}

export function normalizeContextualizeProduct({
  id,
  variants,
  priceRange,
  compareAtPriceRange,
}: ContextualizeShopifyProduct): ContextualizeProduct {
  return {
    id,
    price: money(priceRange?.minVariantPrice),
    listPrice: money(compareAtPriceRange?.minVariantPrice),
    variants: variants ? normalizeContexualizeProductVariants(variants) : [],
  }
}

const normalizeContexualizeProductVariants = ({
  edges,
}: ContextualizeProductVariantConnection) => {
  return edges?.map(({ node: { id, price, compareAtPrice } }) => {
    return {
      id,
      price: +price.amount,
      listPrice: +compareAtPrice?.amount,
    }
  })
}
